import React from 'react';
import {
    Button, Container, Row, Col,
  } from 'reactstrap';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const PolicyPage = () => {
    return (
        <Layout>
            <SEO title="Policy" />
            <section id="contact" className="full-width bg-primary">
                <h3 className="text-color--primary section-header" style={{ textAlign: 'center' }}>Blindly Safety & Policy Center</h3>
                <Container>
                    <Col style={{ 
                        textAlign: 'justify',
                        textJustify: 'inter-word'
                    }}>
                        <h3>Our Commitment to You</h3>

                        <p>Here at Blindly, we prioritize your safety, security and well-being. As the world’s most popular app for meeting new people, we take pride in safely connecting millions of people every day. As with any dating app, there are times where matches have led to users falling victim to criminal activity. While this number is relatively small at Blindly, we strongly believe that even one incident of misconduct or criminal behavior is one too many.</p>

                        <p>We constantly seek out updates, partnerships and technologies that will enhance and inform our safety efforts while fostering a safe and respectful environment for meeting new people. We are dedicated to creating a safe and positive experience for all users on the Blindly platform. To learn more about our safety tools and practices, please read below.</p> 

                        <h3>Safety Tools We Use at Blindly</h3>

                        <p>We invest significant resources in a network of industry-leading automated and manual moderation and review tools, systems, and processes to prevent, monitor and remove inappropriate behavior (impersonation, harassment and more) from the Blindly app. Such tools include automatic profile scans for red-flag language and images; manual reviews of suspicious profiles, activity and user-generated reports; shadow-banning suspicious accounts; and the blocking of email addresses, phone numbers and IP addresses. We will not associate with those acting inappropriately.</p>

                        <p>At Blindly, we have a zero-tolerance policy when it comes to harassment, which is why we encourage the Blindly community to report any instance of misconduct and/or anyone who violates our Community Guidelines or Terms of Use. This can be done with the self-reporting tool featured on all Blindly profiles or by contacting us directly. In the event that we receive such a report, our dedicated team will take appropriate measures to remedy the problem, which could include removing the inappropriate content, banning the user or notifying the appropriate law enforcement resources.</p>

                        <p>We want to bring people together in a safe way, which is why we are continually evaluating and refining our processes. In an effort to protect you and all Blindly users, we work with law enforcement whenever possible and attempt to identify the alleged perpetrator so we can block the associated account. Next, we report the incident to Blindly’s centralized safety repository and check across our various brands to see whether the user has other accounts on our platforms. If we find any, they will be blocked as well.</p>

                        <p>To educate and empower our users to make smart and safe decisions while interacting on Blindly, we offer safety tips both online and in-app.</p>

                        <h3>Forbidden Content</h3>

                        <p>We want you to be able to express yourself on the Blindly app, after all, how is anyone supposed to get to know you if you don’t? Feel free to be yourself, but please note that, in an effort to be a safe, fun and respectful place for everyone, there is content that we simply don’t allow on Blindly:</p>

                        <ul>
                        <li>That which contains language or imagery that could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy another user</li>
                        <li>That which is obscene, pornographic, violent or otherwise may offend human dignity</li>
                        <li>That which is abusive, insulting or threatening, discriminatory or which promotes or encourages racism, sexism, hatred or bigotry</li>
                        <li>That which encourages any illegal activity including—without limitation—terrorism, inciting racial, sexist, xenophobic or homophobic hatred or the submission of which in itself constitutes committing a criminal offense</li>
                        <li>That which is defamatory or libelous</li>
                        <li>That which relates to commercial activities including—without limitation—sales, competitions and advertising, links to other websites or premium line telephone numbers</li>
                        <li>That which involves the transmission of "junk" mail or "spam" or contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Blindly or otherwise</li>
                        <li>That which is itself, or the posting of which, infringes any third party's rights, including—without limitation—intellectual property rights and privacy rights</li>
                        <li>That which shows another person that was created or distributed without that person’s express consent</li>
                        </ul>

                        <p>Blindly operates a zero-tolerance policy for this kind of content.</p>

                        <h3>Fraud</h3>

                        <p>We don’t mess around with fraud. Period. We don’t tolerate predatory behavior of any kind, which is why we have a dedicated fraud team. They utilize a network of industry-leading technology that scans for fraud and reviews each and every member profile for red-flag language. We also conduct manual reviews of suspicious profiles, activity and user-generated reports.</p>

                        <p>No one—whether they met on Blindly or not—should ever send money to someone they haven’t met in person. Additionally, we encourage users to report any individual who has requested financial information via our self-reporting tool. These two steps will greatly assist in stopping almost every scam in its tracks and help protect the next potential victim. This is the Blindly way.</p>

                        <h3>Data Privacy & Platform Security</h3>

                        <p>Here at Blindly, your privacy is our top priority. We go above and beyond applicable privacy laws and industry standards to protect your privacy. We understand that the time you spend on the app is private, which is why we have strict policies and technical systems in place to protect you. This includes encryption for user photos and messages as well as tools that restrict employee access to user communications and other user data. When it comes to Blindy’s users’ data, our sole mandate is keeping our users safe and their data private.</p>

                        <p>Unlike many other social apps out there, Blindly’s business model is focused on providing users with premium features (including in-app upgrades and subscriptions) to enhance your experience on our app. Your data is yours. It’s just that simple. As such, you can request a copy of your data here at any time. The most up-to-date information on our data practices is always available to the general public through our Privacy Policy, which can be also accessed through our app.</p>

                        <p>We are constantly updating and improving our defenses against those with malicious intent. However, we don’t discuss any specific security tools that we use or enhancements we may implement to best protect our users and avoid tipping off any would-be offenders.</p>

                        <h3>Third-Party Stores & In-App Purchases</h3>

                        <p>The Blindly app may be dependent on and/or interoperate with third-party owned and/or operated platforms and services such as Apple (iTunes), Google, Facebook, Twitter and so on (each  a “Third-Party Platform”) and may require that you be a registered member of such Third-Party Platforms and provide certain account credentials and other information in order to access the app. By using Blindly, you agree to comply with any applicable terms, conditions or requirements promulgated by any provider of a Third-Party Platform (Facebook’s Terms of Use, iTunes Store Terms of Use, etc.).</p>

                        <p>We may make available certain products and/or services to Blindly users in consideration of a subscription fee or other fees (“Premium Services”), including the ability to purchase products, services and enhancements, such as the ability to extend your matches (“in-app products”). Should you choose to use our Premium Services or purchase our in-app products, you acknowledge that additional terms may apply to your use of, access to, and purchase of such Premium Services and in-app products, and such additional terms are incorporated herein by reference.</p>

                        <p>You can purchase Premium Services and in-app products with the following payment methods (each is a “Premium Payment Method”):</p>

                        <p>Making a purchase through the Apple App Store®, Google Play or other mobile or web application platforms or storefronts authorized by us (each is a “Third Party Store”)</p>


                        <p>Paying with your credit card, debit card or PayPal account, which will be processed by a third-party processor</p>


                        <p>Adding charges to your mobile carrier bill and remitting payment directly to your carrier. Once you have requested a Premium Service or in-app product, you authorize us to charge your chosen Premium Payment Method and your payment is non-refundable. If payment is not received by us from your chosen Premium Payment Method, you agree to promptly pay all amounts due upon demand by us</p>

                        <p>If you want to cancel or change your Premium Payment Method at any time, you can do so either via the payment settings option on your profile or by contacting your mobile service provider. If your chosen Premium Payment Method is via your mobile service provider, make sure to check with them about payment terms as their payment terms will govern how payments to the Blindly team are made as well as how such payments may be changed or cancelled.</p>

                        <p>Your subscription to Blindly’s Premium Services will automatically renew until you decide to cancel in accordance with such terms. In the event of a conflict between a third-party store’s terms and conditions and these terms, the terms and conditions of the third-party store or service provider shall govern and control. We are not responsible and have no liability whatsoever for goods or services you obtain through the third-party store, our third-party service providers or other websites or webpages. We encourage you to make whatever investigation you feel necessary or appropriate before proceeding with any online transaction with any of these third parties.</p>

                        <p>Should you choose to purchase an in-app product, you will be prompted to enter details for your account with the third-party store you’re using (Android, Apple, etc.) and your mobile platform account will be charged for the Premium Service and/or in-app product in accordance with the terms disclosed to you at the time of purchase in addition to the general terms applicable to all other in-app purchases made through your mobile platform account (Android, Apple, etc.). Premium Services and in-app products may include one-time purchases as well as monthly subscriptions (a one-month subscription, three-month subscription, six-month subscription, etc.) to additional account features. At the end of the free trial period (if any), you will be charged the subscription fee and will continue being charged until you cancel your subscription. Please note that for Premium Services and in-app products bought on a subscription basis, your subscription will automatically renew for the same subscription period as you initially purchased (e.g., if you purchased an in-app product on a six-month subscription, your subscription will be automatically renewed for an additional six months). To avoid any charges for additional periods, you must cancel before the end of the free trial period, subscription period or renewal, as applicable, in accordance with the terms and conditions of your mobile platform account and the terms and conditions of any applicable third-party store. The pricing may vary due to a number of factors, including but not limited to promotional offers, loyalty bonuses and other discounts that might apply to your age group.</p>

                        <p>Please note that for Premium Services and in-app products, you will be billed continuously for the subscription or service until you cancel in accordance with your mobile platform account’s or your Premium Payment Method’s terms. In all cases, we are not responsible and have no liability whatsoever for any payment processing errors (including card processing, identity verification, analysis and regulatory compliance) or fees or other service-related issues (including those issues that may arise from inaccurate account information or products or goods you obtain through your Mobile Platform Account or third-party stores). Further, Blindly does not guarantee that product descriptions or other content and products will be available, accurate, complete, reliable, current or error-free.</p>

                        <p>Descriptions and images of, and references to, products or services (including Premium Services or in-app products) do not imply our or any of our affiliates' endorsement of such products or services. Moreover, Blindly and its third-party operational service providers reserve the right, with or without prior notice, for any or no reason, to change product descriptions, images and references; to limit the available quantity of any product; to honor, or impose conditions on the honoring of, any coupon, coupon code, promotional code or other similar promotions; to bar any user from conducting any or all transaction(s); and/or to refuse to provide any user with any product. Further, if we terminate your use of or registration to the app because you have breached these terms, you shall not be entitled to a refund of any unused portion of any fees, payments or other consideration. We encourage you to review the terms and conditions of the applicable third-party payment processors, third-party store or mobile platform account before you make any in-app products or Premium Service purchases.</p>

                        <h3>Push Notifications & Location-Based Features</h3>

                        <p>We may send you emails, text messages, push notifications, alerts and other messages related to the app and/or Blindly services such as enhancements, offers, products, events and other promotions. After downloading the app, you will be asked to accept or deny push notifications/alerts. If you deny, you will not receive any push notifications/alerts. If you accept, push notifications/alerts will be automatically sent to you. If you no longer wish to receive push notifications/alerts from the app, you may opt out by changing your notification settings on your mobile device. With respect to other types of messaging or communications such as emails, text messages and more, you can unsubscribe or opt out by following the specific instructions included in such communications.</p>

                        <p>Blindly may allow access to or make available opportunities for you to view certain content and receive other products, services and/or other materials based on your location. To make these opportunities available to you, the app will determine your location using one or more reference points such as GPS, Bluetooth and/or software within your mobile device. If you have set your mobile device to disable GPS, Bluetooth or other location-determining software or do not authorize Blindly to access your location data, you will not be able to access such location-specific content, products, services and materials.</p>

                        <h3>Additional Blindly Policies & Information</h3>

                        <p>Blindly was built to introduce our community of users to new people, and while this is a lot of fun, it’s important to remember the Community Guidelines that make Blindly truly the best app for meeting new people. All users must abide by these guidelines.</p>

                        <p>We encourage users to report any instance of bad behavior on or off our platform. Reporting a user for inappropriate conduct is straightforward and easy to do. On any given profile, users can tap the three dots icon (...) and select the “Report” option or email us at help@Blindly.com to report a user.</p>

                        <p>Please see below for more details about our policies that ensure Blindly stays a safe place for meeting new people.</p>

                        <h3>Dedication to Inclusion</h3>

                        <p>Regardless of your gender identity, sexual orientation, ethnicity, race, sex or background, Blindly welcomes you. We strive to make the Blindly app inclusive for all and have worked closely with organizations around the world to constantly improve our inclusion practices. Today, we offer our users more than 50 gender identity options in countries around the world and nine (9) sexual orientation options.</p>

                        <h3>Harassment</h3>

                        <p>At Blindly, we have a zero-tolerance policy for harassment on our platform. We encourage all users to report any unacceptable behavior so that the community team can investigate and take appropriate action.</p>

                        <h3>Underage Usage</h3>

                        <p>Blindly is for users aged 18+ only. We work diligently to maintain an adult-only community on Blindly and utilize a network of industry-leading automated and manual moderation and review tools, systems, and processes to ensure this. We also invest significant resources in preventing and removing minors from our app.</p>

                        <p>The tools we use may include automatic profile scans for red-flag language and images, manual reviews of suspicious profiles, activity and user-generated reports, and the blocking of email addresses, phone numbers and other identifiers associated with underage users trying to circumvent these restrictions. We also depend on you to report any profiles that may depict a minor so we can investigate and remove them accordingly.</p>

                        <p>We do not tolerate minors on Blindly. Period.</p>

                        <h3>Solicitation/Prostitution</h3>

                        <p>Solicitation of any kind—whether attempting to buy our sell—violates our Terms of Use. If you come across a profile violating such terms, we encourage you to report it via the self-reporting tool featured on all Blindly profiles or online. Reporting this kind of activity allows our community team to take appropriate measures, which may include removing the profile and/or banning the user.</p>

                        <h3>Illegal Substances</h3>

                        <p>Profiles (or Blindly users) promoting illegal substances violate our Terms of Use. Our system will remove these profiles from the Blindly app. If you come across a profile violating these terms, please report it within the app.</p>

                        <h3>Get Support, Advice & Assistance</h3>

                        <p>In case of emergency, call 911 (US and Canada) or your local law enforcement agency. Please see below for additional resources in the US. If you’re outside the US, click on the links below for a list of resources across many of the countries in which we operate.</p>

                        <p>Rape, Abuse & National Network (RAINN) Hotline: 1.800.656.HOPE (4673) | <a href="https://www.rainn.org/">www.rainn.org</a></p>


                        <p>Planned Parenthood: 1.800.230.7526 | <a href="https://www.plannedparenthood.org/">www.plannedparenthood.org</a></p>


                        <p>National Domestic Violence Hotline: 1.800.799.SAFE (7233) or 1.800.787.3224 | <a href="https://www.thehotline.org/">www.thehotline.org</a></p>


                        <p>National Human Trafficking Hotline: 1.888.272.7888 or text 233733 | <a href="https://www.humantraffickinghotline.org/">www.humantraffickinghotline.org</a></p>


                        <p>National Sexual Violence Resource Center: 1.877.739.3895 | <a href="https://www.nsvrc.org/">www.nsvrc.org</a></p>


                        <p>National Center for Missing & Exploited Children: 1.800.THE.LOST (843.5678) | <a href="http://www.cybertipline.org">www.cybertipline.org</a></p>


                        <p>Cyber Civil Rights Initiative: 1.844.878.2274 | <a href="https://www.cybercivilrights.org/">www.cybercivilrights.org</a></p>


                        <p>FBI Internet Crime Complaint Center: <a href="https://www.ic3.gov/">www.ic3.gov</a></p>
                    </Col>
                </Container>
            </section>
        </Layout>
    );
}

export default PolicyPage;